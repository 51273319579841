import { useContext } from "react";
import Header from "../../components/Header/Header"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";
import classes from "./subPages.module.css"


const DataProtection = () => {
    const ctx = useContext(AuthContext);

    const privacy = ctx.text.privacy
    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div className={classes.legalsContainer}>
                    <h1>{privacy.h1}</h1>

                    <h2>{privacy.h2_1}</h2>
                    <h3>{privacy.h3_1}</h3>
                    <p>{privacy.p_1}</p>

                    <h3>{privacy.h3_2}</h3>
                    <h3>{privacy.h3_3}</h3>
                    <p>{privacy.p_2}</p>

                    <h3>{privacy.h3_4}</h3>
                    <p>{privacy.p_3}</p>
                    <p>{privacy.p_4}</p>

                    <h3>{privacy.h3_5}</h3>
                    <p>{privacy.p_5}</p>

                    <h3>{privacy.h3_6}</h3>
                    <p>{privacy.p_6}</p>

                    <h3>{privacy.h3_7}</h3>
                    <p>{privacy.p_7}</p>

                    <h2>{privacy.h2_2}</h2>
                    <p>{privacy.p_8}</p>

                    <h3>{privacy.h3_8}</h3>
                    <p>{privacy.p_9}</p>
                    <p>{privacy.p_10}</p>

                    <h3>{privacy.h3_9}</h3>
                    <p>{privacy.p_11}</p>

                    <h2>{privacy.h2_3}</h2>
                    <h3>{privacy.h3_10}</h3>
                    <p>{privacy.p_12}</p>
                    <p>{privacy.p_13}</p>
                    <p>{privacy.p_14}</p>

                    <h3>{privacy.h3_11}</h3>
                    <p>{privacy.p_15}</p>
                    <p>{privacy.p_16}</p>
                    <p>{privacy.p_17}</p>
                    <p>{privacy.p_18}</p>
                    <p>{privacy.p_19}</p>
                    <p>{privacy.p_20}</p>

                    <h3>{privacy.h3_12}</h3>
                    <p>{privacy.p_21}</p>

                    <h3>{privacy.h3_13}</h3>
                    <p>{privacy.p_22}</p>

                    <h3>{privacy.h3_14}</h3>
                    <p>{privacy.p_23}</p>

                    <h3>{privacy.h3_15}</h3>
                    <p>{privacy.p_24}</p>

                    <h3>{privacy.h3_16}</h3>
                    <p>{privacy.p_25}</p>

                    <h3>{privacy.h3_17}</h3>
                    <p>{privacy.p_26}</p>

                    <h3>{privacy.h3_18}</h3>
                    <p>{privacy.p_27}</p>

                    <h3>{privacy.h3_19}</h3>
                    <p>{privacy.p_28}</p>

                    <h3>{privacy.h3_20}</h3>
                    <p>{privacy.p_29}</p>
                    <p>{privacy.p_30}</p>
                    <p>{privacy.p_31}</p>

                    <h3>{privacy.h3_21}</h3>
                    <p>{privacy.p_32}</p>
                    <p>{privacy.p_33}</p>

                    <h3>{privacy.h3_22}</h3>
                    <p>{privacy.p_34}</p>

                    <h2>{privacy.h2_4}</h2>
                    <h3>{privacy.h3_23}</h3>
                    <p>{privacy.p_35}</p>
                    <p>{privacy.p_36}</p>
                    <p>{privacy.p_37}</p>
                    <p>{privacy.p_38}</p>
                    <p>{privacy.p_39}</p>
                    <p>{privacy.p_40}</p>

                    <h3>{privacy.h3_24}</h3>
                    <p>{privacy.p_41}</p>
                    <p>{privacy.p_42}</p>
                    <p>{privacy.p_43}</p>
                    <p>{privacy.p_44}</p>
                    <p>{privacy.p_45}</p>
                    <p>{privacy.p_46}</p>
                    <p>{privacy.p_47}</p>
                    <p>{privacy.p_48}</p>
                    <p>{privacy.p_49}</p>

                    <h3>{privacy.h3_25}</h3>
                    <p>{privacy.p_50}</p>
                    <p>{privacy.p_51}</p>
                    <p>{privacy.p_52}</p>

                    <h3>{privacy.h3_26}</h3>
                    <p>{privacy.p_53}</p>
                    <p>{privacy.p_54}</p>
                    <p>{privacy.p_55}</p>

                    <h3>{privacy.h3_27}</h3>
                    <p>{privacy.p_56}</p>

                    <h3>{privacy.h3_28}</h3>
                    <p>{privacy.p_57}</p>
                    <p>{privacy.p_58}</p>
                    <p>{privacy.p_59}</p>
                    <p>{privacy.p_60}</p>
                    <p>{privacy.p_61}</p>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default DataProtection;