import { useContext } from "react";
import Header from "../../components/Header/Header"
import classes from "./subPages.module.css"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";


const TermsandConditions = () => {

    const ctx = useContext(AuthContext)

    const agb = ctx.text.agb;

    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div className={classes.legalsContainer}>
                    <h1>{agb.title}</h1>
                    <h2>{agb.overview}</h2>
                    <p>{agb.intro_1}</p>
                    <p>{agb.intro_2}</p>
                    <p>{agb.intro_3}</p>
                    <h2>{agb.section_1_title}</h2>
                    <p>{agb.section_1_content}</p>
                    <h2>{agb.section_2_title}</h2>
                    <p>{agb.section_2_content}</p>
                    <h2>{agb.section_3_title}</h2>
                    <p>{agb.section_3_content}</p>
                    <h2>{agb.section_4_title}</h2>
                    <p>{agb.section_4_content}</p>
                    <h2>{agb.section_5_title}</h2>
                    <p>{agb.section_5_content}</p>
                    <h2>{agb.section_6_title}</h2>
                    <p>{agb.section_6_content}</p>
                    <h2>{agb.section_7_title}</h2>
                    <p>{agb.section_7_content}</p>
                    <h2>{agb.section_8_title}</h2>
                    <p>{agb.section_8_content}</p>
                    <h2>{agb.section_9_title}</h2>
                    <p>{agb.section_9_content}</p>
                    <h2>{agb.section_10_title}</h2>
                    <p>{agb.section_10_content}</p>
                    <h2>{agb.section_11_title}</h2>
                    <p>{agb.section_11_content}</p>
                    <h2>{agb.section_12_title}</h2>
                    <p>{agb.section_12_content}</p>
                    <h2>{agb.section_13_title}</h2>
                    <p>{agb.section_13_content}</p>
                    <h2>{agb.section_14_title}</h2>
                    <p>{agb.section_14_content}</p>
                    <h2>{agb.section_15_title}</h2>
                    <p>{agb.section_15_content}</p>
                    <h2>{agb.section_16_title}</h2>
                    <p>{agb.contact_info}</p>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default TermsandConditions;