import { useContext } from "react";
import Header from "../../components/Header/Header"
import classes from "./subPages.module.css"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";

const Impressum = () => {
    const ctx = useContext(AuthContext);

    const inprint = ctx.text.inprint;

    const createMarkup = (text) => {
        return { __html: text };
    };

    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div className={classes.legalsContainer}>
                    <h1>{inprint.title}</h1>
                    <h2>{inprint.section_5_tmg}</h2>
                    <h3>{inprint.contact}</h3>
                    <p dangerouslySetInnerHTML={createMarkup(inprint.contact_details)}></p>
                    <h3>{inprint.editorial_responsible}</h3>
                    <p dangerouslySetInnerHTML={createMarkup(inprint.editorial_responsible_details)}></p>
                    <h3>{inprint.eu_dispute_resolution}</h3>
                    <p dangerouslySetInnerHTML={createMarkup(inprint.eu_dispute_resolution_details)}></p>
                    <h3>{inprint.consumer_dispute_resolution}</h3>
                    <p dangerouslySetInnerHTML={createMarkup(inprint.consumer_dispute_resolution_details)}></p>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Impressum;