import classes from "./FormIntro.module.css"
import Form from "../../UI/Form/Form";
import { useContext, useState } from "react";
import AuthContext from "../../../store/auth-context";

const FormIntro = (props) => {
    const ctx = useContext(AuthContext);

    const [isMapVisible, setIsMapVisible] = useState(false);

    const handleMapDisplay = () => {
        setIsMapVisible(true);
    };

    return (
        <section className={classes.wrapper}>
            <div className={classes.formContainer} style={props.style}>
                <Form className={classes.form} />
                <div className={classes.mapContainer} onClick={handleMapDisplay}>
                    <iframe
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.8878987024495!2d13.6906669!3d51.0736194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cfc0ae406f2d%3A0x7eb18cd52969617a!2sOverbeckstra%C3%9Fe%2033%2C%2001139%20Dresden!5e0!3m2!1sde!2sde!4v1696324721726!5m2!1sde!2sde"
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    {!isMapVisible && (
                        <div className={classes.mapOverlay}>
                            {ctx.text.Map.mapMessage}
                        </div>
                    )}
                </div>

                {/* <div className={classes.mapContainer}>
                    <iframe title="AE Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.889013526185!2d13.68917104281489!3d51.073598821112135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cfc0ae406f2d%3A0x7eb18cd52969617a!2sOverbeckstra%C3%9Fe%2033%2C%2001139%20Dresden%2C%20Allemagne!5e0!3m2!1sfr!2sch!4v1664473510854!5m2!1sfr!2sch" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div> */}
            </div>
        </section>
    )
}

export default FormIntro;