
import { useContext } from 'react';
import classes from "./AeMain.module.css"
import vid from "../../video/Video_AE.mp4"
import AuthContext from '../../store/auth-context';
import manualIcon from "../../Img/manualsIcon.png"

import DE from "../../manual/Manual_AllergoEye_DE_2.8.pdf"
import EN from "../../manual/Manual_AllergoEye_ENG_2.8.pdf"
import FR from "../../manual/Manual_AllergoEye_FR.pdf"
import ES from "../../manual/Manual_AllergoEye_ESP.pdf"
import CN from "../../manual/Manual_AllergoEye_ENG_2.8.pdf"

import logo from "../../Img/logo_b.png"

const CardRight = (props) => {
    const ctx = useContext(AuthContext)
    const data = ctx.text.AE;
    const scrollY = props.scrollY
    const lang = ctx.languageID
    let fading = scrollY >= 100 ? classes.animation : classes.text;

    const manuals = {
        DE: DE,
        EN: EN,
        FR: FR,
        ES: ES,
        CN: CN,
    };

    const download = (e) => {
        e.preventDefault();
        const manualToDownload = manuals[lang] || EN;
        const link = document.createElement('a');
        link.href = manualToDownload;
        link.download = `Manual_AllergoEye_${lang}.pdf`;
        link.click();
    };


    return (
        <section className={classes.wrapperVid}>
            <div className={`${fading} ${classes.textWrap} ${classes.reSizeText}`}>
                <p>
                    {data.RightP}
                </p>
                <div className={classes.manualWrapper}>
                    <a className={classes.menuItem} href="/#" onClick={download}>
                        <img src={manualIcon} alt="Manual" />
                        {data.manual}</a>
                    <a className={classes.menuItem} href={`https://www.allergoeye.com?lang=${lang.toLocaleLowerCase()}`} style={{ margin: '1rem 0', fontSize: '1rem', fontWeight: '600' }}>
                        <img src={logo} style={{ background: "#fff", width: "30%" }} alt="Manual" />

                        {data.website}</a>

                </div>
            </div>
            <div className={classes.vidContainer2}>
                <video width="100%"
                    controls
                    playsInline
                >
                    <source src={vid} type="video/webm" />
                </video>
            </div>
        </section>
    )
}

export default CardRight;